@font-face {
   font-family: 'IRANSans';
   src: url('./fonts/IRANSans/IRANSans(FaNum).ttf') format('truetype');
   font-weight: 400;
}

@font-face {
   font-family: 'IRANSans';
   src: url('./fonts/IRANSans/IRANSans(FaNum)_Light.ttf') format('truetype');
   font-weight: 200;
}

@font-face {
   font-family: 'IRANSans';
   src: url('./fonts/IRANSans/IRANSans(FaNum)_Medium.ttf') format('truetype');
   font-weight: 600;
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
